import React from "react"

const form_check = () => {
  return (
    <form
      action="https://front-desk.propertybase.com/forms/b443c1ad5357100482859621a5945425cdb00edb"
      method="post"
    >
      <p>
        <b>First Name</b>
      </p>
      <p>
        <input type="text" name="contact[FirstName]" />
      </p>
      <p>
        <b>Last Name</b>
      </p>
      <p>
        <input type="text" name="contact[LastName]" />
      </p>
      <p>
        <b>Telephone</b>
      </p>
      <p>
        <input type="Phone" name="contact[MobilePhone]" />
      </p>
      <p>
        <b>Email</b>
      </p>
      <p>
        <input type="Email" name="contact[Email]" />
      </p>
      <p>
        <b>Additional Message</b>
      </p>
      <p>
        <input
          type="Long Text Area(32768)"
          name="contact[Something_interesting_about_the_client__c]"
        />
      </p>
      <p>
        <button type="submit">Submit</button>
      </p>
      <input
        type="text"
        name="h_b443c1ad5357100482859621a5945425cdb00edb"
        value=""
        style={{ display: "none" }}
      />
    </form>
  )
}

export default form_check
